const { SnoozeTimer } = require('./alarmClock');
const {elementsCollection} = require('./uxReference').getUxReferenceWithElementsCollection()
const {pauseAlarm,playAlarm,setNewAlarmAudioSrc,skipAlarmAudioTo,muteAlarmAudio,unmuteAlarmAudioAfterEntersInRange} = require('./alarmHandlers')

// TODO: herek and in alarmHandlers, handlers are mixed up with listeners, should separate!


/**
 * UI handling of SnoozeTimer Events
 * @param {SnoozeTimer} snoozeTimer
 */
function handleSnoozeEvents(snoozeTimer) {
    snoozeTimer.events.on("timerStarted",function(){
        pauseAlarm()
        disableSnoozeButton();
        // disableSnoozeDisplayBlink();
    })
    
    snoozeTimer.events.on("timeChanged", function(data){ // TODO: event on and emits should be better typed
        const currentTime = new Date(data);
        updateSnoozeTimerDisplay(currentTime);
    })

    snoozeTimer.events.on("timerFinished", function(){
        enableSnoozeButton();
        updateSnoozeTimerDisplay(new Date(0));
        playAlarm(snoozeTimer);
        // enableSnoozeDisplayBlink()

    })

    snoozeTimer.events.on("newAlarmFilename", function( /** @type {string} */ filename){
        setNewAlarmAudioSrc(filename);
    })

    snoozeTimer.events.on("skipAudioTo", function( /** @type {number} */ time){
        skipAlarmAudioTo(time);
    })

    // DEBUG: need to test this 
    snoozeTimer.events.on(
        "muteForInitialDelay",
        /** @type {(args : number[]) => void} range values in ms */
        (args)=>{
            const [rangeStart,rangeEnd] = args;
        muteAlarmAudio()
        unmuteAlarmAudioAfterEntersInRange(rangeStart,rangeEnd);
    })
}

/**
 * @param {Date} remainingTime current time as Date.now object
 */
function updateSnoozeTimerDisplay(remainingTime){    
    if(!(elementsCollection.snoozeCountdownDisplay instanceof HTMLElement)){
        console.warn('failed to update time display in DOM, element not found');        
    }else{
        const remainingSeconds = remainingTime.getUTCSeconds().toString().padStart(2,'0');
        const remainingMinutes = remainingTime.getUTCMinutes().toString().padStart(2,'0');
        const remainingHours = remainingTime.getUTCHours().toString().padStart(2,'0');
        const remainingTimeString = [remainingHours,remainingMinutes,remainingSeconds].join(":")

        elementsCollection.snoozeCountdownDisplay.innerText = "00:00:"+remainingTimeString;
    }
}

function enableSnoozeButton(){
    const snoozeButtonWrapper = elementsCollection.snoozeButtonWrapper;
    snoozeButtonWrapper.setAttribute('data-isActive','true');
    const snoozeButton = /** @type {HTMLButtonElement} */ (elementsCollection.snoozeButton);
    // snoozeButton.disabled = false; //todo: rm this, not using <button> anymore
    snoozeButton.setAttribute('data-isActive','true');
}

function disableSnoozeButton(){
    const snoozeButtonWrapper = elementsCollection.snoozeButtonWrapper;
    snoozeButtonWrapper.setAttribute('data-isActive','false');
    const snoozeButton = /** @type {HTMLButtonElement} */ (elementsCollection.snoozeButton);
    // snoozeButton.disabled = true; //todo: rm this, not using <button> anymore
    snoozeButton.setAttribute('data-isActive','false');
}

function enableSnoozeDisplayBlink(){
    const snoozeCountdownWrapper = elementsCollection.snoozeCountdownWrapper
    snoozeCountdownWrapper.classList.add('blink');
}

function disableSnoozeDisplayBlink(){
    const snoozeCountdownWrapper = elementsCollection.snoozeCountdownWrapper
    snoozeCountdownWrapper.classList.remove('blink');
}


module.exports = {    
    handleSnoozeEvents
}
