// NOTE: WHAT ABOUT VIBRATIONS
// TODO: IMPORTANT ! render the landing page graphic on the alarm page, on click hide and run the rest of the logic (socket, snoozer etc) - this will enable audio playback

// @ts-ignore
if(module.hot) module.hot.accept()       


const { debuggingTools } = require('./helpers');
require('./styles');

const LandingPosterAwaitClick = require('./landingPosterAwaitClick')
LandingPosterAwaitClick(main)

function main() {
    const {
        requestCurrentServerTime,
        snoozeTimerSocketEmitter,
        socket } = require('./socketSetup')();
    
    const { listenToUiEvents } = require('./uiListeners');
    const { handleSnoozeEvents } = require('./uxHandlers');
    const { SnoozeTimer, startSyncingTimeWithServer } = require('./alarmClock');
    
    
    startSyncingTimeWithServer(requestCurrentServerTime)
    
    const snoozeTimer = new SnoozeTimer(snoozeTimerSocketEmitter, 10)
    
    listenToUiEvents(snoozeTimer) ;
    handleSnoozeEvents(snoozeTimer) ;
    
    debuggingTools.exposeInWindowObject({socket});
    debuggingTools.exposeInWindowObject({snoozeTimer});
}

// const clock = new Clock() // REVIEW: i don't think we're using clock but need final decision

