function setup() {

    // height adjust
    function adjustHeight() {
        const viewportHeight = window.visualViewport.height;
        document.documentElement.style.setProperty('--vh', `${viewportHeight * 0.01}px`);
    }
    function adjustHeightFallback() {
        const viewportHeight = window.innerHeight;
        document.documentElement.style.setProperty('--vh', `${viewportHeight * 0.01}px`);
    }
    if (window.visualViewport) {
        window.visualViewport.addEventListener('resize', adjustHeight);
        adjustHeight()
    } else {
        window.addEventListener('resize', adjustHeightFallback);
        adjustHeightFallback()
    }

    // prevent refresh by scroll
    /* document.addEventListener('touchmove', function(event) {
        // Prevent default only when at the top or bottom
        if (window.scrollY === 0 || (window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            event.preventDefault();
        }
    }, { passive: false }); // Passive must be false to allow preventDefault()     */
    window.addEventListener('beforeunload', function (event) {
        event.preventDefault();
    });

    
    
    
    // set to as css var - inner margins of div with landing poaster as background-image;
    // '--image-margin-x' '--image-margin-y'
    const posterElement = document.querySelector('.landing-poster');
    function getInnerMarginsOfDivWithBackgroundImage(elem) {
        const container = elem;
        const style = getComputedStyle(container);
    
        // Get the container dimensions
        const containerWidth = parseFloat(style.width);
        const containerHeight = parseFloat(style.height);
    
        // Set the image dimensions (aspect ratio)
        const imageAspectRatio = 800 / 1420; // Replace with your image's ratio
    
        // Calculate the scaled dimensions of the image
        const containerAspectRatio = containerWidth / containerHeight;
    
        let scaledImageWidth, scaledImageHeight;
        let horizontalPadding = 0;
        let verticalPadding = 0;
    
        if (imageAspectRatio > containerAspectRatio) {
            // Image is wider, extra space on top and bottom
            scaledImageHeight = containerWidth / imageAspectRatio;
            scaledImageWidth = containerWidth;
            verticalPadding = (containerHeight - scaledImageHeight) / 2;
        } else {
            // Image is taller, extra space on sides
            scaledImageWidth = containerHeight * imageAspectRatio;
            scaledImageHeight = containerHeight;
            horizontalPadding = (containerWidth - scaledImageWidth) / 2;
        }
    
        // Set CSS variables for margins
        document.documentElement.style.setProperty('--poster-image-margin-x', `${horizontalPadding}px`);
        document.documentElement.style.setProperty('--poster-image-margin-y', `${verticalPadding}px`);
    
        // Set CSS variables for scaled image dimensions
        document.documentElement.style.setProperty('--poster-image-width-%', `${scaledImageWidth/100}px`);
        document.documentElement.style.setProperty('--poster-image-height-%', `${scaledImageHeight/100}px`);
    
        // Optional console logs for debugging
        // console.log('Horizontal Padding:', horizontalPadding);
        // console.log('Vertical Padding:', verticalPadding);
        // console.log('Scaled Image Width:', scaledImageWidth);
        // console.log('Scaled Image Height:', scaledImageHeight);
    }
    
    window.addEventListener('resize',function(){
        getInnerMarginsOfDivWithBackgroundImage(posterElement);
    })
    getInnerMarginsOfDivWithBackgroundImage(posterElement)

}










if (document.readyState === "loading") {
    // Document is still loading
    document.addEventListener("DOMContentLoaded", function () {
        setup();
    });
} else {
    // Document is already loaded
    setup();
}

module.exports = {}