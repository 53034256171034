const { SnoozeTimer } = require('./alarmClock');
const {elementsCollection} = require('./uxReference').getUxReferenceWithElementsCollection()


// TODO: are we restarting from 0 on each play ? or picking up from where we paused 
// TODO: the canon actually should fizzle out, cause otherwise it would fade in only once, at the beginning. 
/* TODO: ❗ if CANON  : each client should receive :
    the interval length (ie. the bar) - this is the same for all, and is the time the melody will have to WAIT BEFORE STARTING FROM THE TOP
    the initial delay (ie. 1 x bar or 2 x bar, etc.) - this varies per group - it tells alarm group how long to wait AT FIRST, BEFORE JOINING IN WITH THEIR MELODY (so if we loop, and pause where every alarm is in the melody, than this delay will only be used ONCE)
    the name of the file to play (ie which instrument)
*/

let shouldAlarmAudioBePlaying = false;
const alarmAudioElement = /** @type {HTMLAudioElement} */ (elementsCollection.alarmAudioElement);
// DEL: alarmAudioElement.onplay = function () { shouldAlarmAudioBePlaying = true; };
// DEL: alarmAudioElement.onpause = function () { shouldAlarmAudioBePlaying = false; };
// let delayUpdateIntervalId;

if (!alarmAudioElement.loop){
    alarmAudioElement.addEventListener('ended', () => {
        alarmAudioElement.currentTime = 0;
        alarmAudioElement.play();
    });
}


/** @param {SnoozeTimer} snoozeTimer */
function playAlarm(snoozeTimer) {
    // const {getLocalTimeOffset, changeRate} = snoozeTimer;
    if (!alarmAudioElement.src) console.warn('Audio element has no src!')
    if (shouldAlarmAudioBePlaying) return console.debug('alarm already playing!');
    shouldAlarmAudioBePlaying = true;
    
    const startsFromMuted = alarmAudioElement.muted

    const initialSkipTo = alarmAudioElement.duration - Math.round(snoozeTimer.alarmCanonInitialDelayRemaining / 1000)
    if(startsFromMuted && alarmAudioElement.currentTime != initialSkipTo) alarmAudioElement.currentTime = initialSkipTo;
    

    console.debug('alarm about to play, starting from:'+alarmAudioElement.currentTime);
    alarmAudioElement.muted = false; // NOTE: IMPORTANT quick fix for the exhibition (prerendered canon voices)
    alarmAudioElement.currentTime = 0;
    alarmAudioElement.play().then(t=>{
        console.debug('alarm playing: '+alarmAudioElement.currentSrc+',\nstarting from: '+alarmAudioElement.currentTime)
    }).catch(e=>{
        console.error('alarm playing, fail:', e)
    });
    

    

    
    // if(snoozeTimer.alarmCanonInitialDelayRemaining>0){
    //     const delayEndTime = snoozeTimer.alarmCanonInitialDelayRemaining + Date.now() + getLocalTimeOffset();
    //     delayUpdateIntervalId = setInterval(function(){
    //         snoozeTimer.alarmCanonInitialDelayRemaining =  updateRemainingDelayTime(delayEndTime, getLocalTimeOffset );
    //         if(snoozeTimer.alarmCanonInitialDelayRemaining<=0){                
    //             alarmAudioElement.play();
    //             shouldAlarmAudioBePlaying = true;
    //             clearInterval(delayUpdateIntervalId);
    //             delayUpdateIntervalId = null;
    //         }
    //     },changeRate)
    // }else{
    //     alarmAudioElement.play();
    // }   
}

/**
 * @param {EpochTimeStamp} delayEndTime
 * @param {SnoozeTimer['getLocalTimeOffset']} getLocalTimeOffset
 */
function updateRemainingDelayTime(delayEndTime, getLocalTimeOffset){
    return delayEndTime - (Date.now() + getLocalTimeOffset());
}

function pauseAlarm() {
    // clearInterval(delayUpdateIntervalId);
    // delayUpdateIntervalId = null;
    if (!shouldAlarmAudioBePlaying) console.debug('audio should already be paused')    
    alarmAudioElement.pause();
    console.debug('alarm paused');
    shouldAlarmAudioBePlaying = false;
    
}

/** @param {string} filename filename of the alarm audio file */
function setNewAlarmAudioSrc(filename){
    alarmAudioElement.src = `./${filename}`; // NOTE: should be present on the same dir as the page (check routes if doesn't work)
    if(shouldAlarmAudioBePlaying) alarmAudioElement.play()
}

/** Skip audio to specific time (used for offsetting voices for canon effect) 
 * @param {number} time Initial delay time (in ms) */

function skipAlarmAudioTo(time){    
    /* 
    const duration = alarmAudioElement.duration
    if(Number.isFinite(duration)){
        //!!! DEBUG: REWIND INSTEAD OF FORWARD ?
        const skipBy = Math.round((time % (duration * 1000))/1000) 
        alarmAudioElement.pause();
        alarmAudioElement.currentTime = duration - skipBy; // NOTE: alarmAudioElement.currentTime = skipTo;
        console.debug("alarm audio skipped to: "+ (alarmAudioElement.currentTime));
    }else{
        let handler;        
        alarmAudioElement.addEventListener('durationchange', handler = ()=>{
            const duration = alarmAudioElement.duration;        
            const skipBy = Math.round((time % (duration * 1000))/1000) 
            alarmAudioElement.pause();
            alarmAudioElement.currentTime = duration - skipBy; // NOTE: alarmAudioElement.currentTime = skipTo;
            console.debug("alarm audio skipped to: "+ (alarmAudioElement.currentTime));
            clearTimeout(timeout)
        },{once:true})
        
        const timeout = setTimeout(()=>{
            alarmAudioElement.removeEventListener('durationchange',handler);
            console.warn("Timeout: can't get duration of alarm audio track")
        },4000)
        // on duration availbable
        // timeout, duration not available
    }
     */
}

function muteAlarmAudio(){
    /* 
    console.debug("alarm muted");
    alarmAudioElement.muted = true;
     */
}

/** Unmute audio once playback enters in range
 * @param {number} rangeStart Low-end ( 'bigger than' conditional ), in ms 
 * @param {any} rangeEnd High-end ( 'smaller than' conditional ), in ms */
function unmuteAlarmAudioAfterEntersInRange(rangeStart, rangeEnd) {

/*     
    if (Math.round(rangeStart / 1000) == Math.round(rangeEnd / 1000)) {
        alarmAudioElement.muted = false;
        console.debug("alarm unmuted: " + !alarmAudioElement.muted);
    } else {

        const duration = alarmAudioElement.duration
        if (Number.isFinite(duration)) {
            console.debug('duration change: currentTime :' + alarmAudioElement.currentTime)
            const endMute = (alarmAudioElement.duration - Math.round(rangeEnd / 1000))
            console.debug('endMute: ' + endMute)
            function handler() {
                const currentTime = alarmAudioElement.currentTime
                if (currentTime >= Math.round(rangeStart / 1000) && currentTime < endMute) {
                    alarmAudioElement.muted = false;
                    console.debug("alarm unmuted: " + !alarmAudioElement.muted);
                    alarmAudioElement.removeEventListener('timeupdate', handler)
                }
            }
            alarmAudioElement.removeEventListener('timeupdate', handler);
            alarmAudioElement.addEventListener('timeupdate', handler);

        } else {
            let durationchangeHandler;
            alarmAudioElement.addEventListener('durationchange', durationchangeHandler = () => {
                console.debug('duration change: currentTime :' + alarmAudioElement.currentTime)
                const endMute = (alarmAudioElement.duration - Math.round(rangeEnd / 1000))
                console.debug('endMute: ' + endMute)
                function handler() {
                    const currentTime = alarmAudioElement.currentTime
                    console.log(`currentTime: ${currentTime}, endMute ${endMute}`)
                    if (currentTime >= Math.round(rangeStart / 1000) && currentTime < endMute) {
                        alarmAudioElement.muted = false;
                        console.debug("alarm unmuted: " + !alarmAudioElement.muted);
                        alarmAudioElement.removeEventListener('timeupdate', handler)
                    }
                }
                alarmAudioElement.removeEventListener('timeupdate', handler);
                alarmAudioElement.addEventListener('timeupdate', handler);



                clearTimeout(timeout)
            }, { once: true })

            const timeout = setTimeout(() => {
                alarmAudioElement.removeEventListener('durationchange', durationchangeHandler);
                console.warn("Timeout durationchangeHandler: can't get duration of alarm audio track")
            }, 4000)
            // on duration availbable
            // timeout, duration not available
        }

    }
 */
}

module.exports = {
    playAlarm,
    pauseAlarm,
    setNewAlarmAudioSrc,
    skipAlarmAudioTo,
    muteAlarmAudio,
    unmuteAlarmAudioAfterEntersInRange
}

