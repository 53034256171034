//@ts-check

const { EventEmitter } = require("./EventEmitter");

// ?❓? maybe i dont need socket io, maybe just creating new WebSocket is enough ? i have only 3 types of events or so (snooze, alarmOn, currentTime)
// ❗NO❗ - socketio has fallbacks and reconnection logic


/** @type {number} */
var localTimeOffset = 0;
var isUpdatingLocalTimeOffset = false;
var syncTimeWithServerIntervalId = null;
var syncInterval = parseInt(process.env.PUBLIC_SERVERTIME_SYNC_RATE) || 10000

/** 
 * @callback RequestCurrentServerTime
 * @param {(ServerTimeData)=>void} callback
*/
/** Starts tracking localTime - serverTime offset
 * @param {RequestCurrentServerTime} requestCurrentServerTime
*/
function startSyncingTimeWithServer(requestCurrentServerTime) {
    
    /** Updates offset with serverTime */
    function syncTimeWithServer(requestCurrentServerTime) {        
        try {
            var startTime = Date.now();
    
            
            requestCurrentServerTime(function ( /** @type {ServerTimeData} */ data) {
                var endTime = Date.now()
                var latency = (endTime - startTime) / 2 // 1-way latency
                var correctedServerTime = data.currentServerTime + latency;
                localTimeOffset = correctedServerTime - Date.now()
                isUpdatingLocalTimeOffset = true
            })
        } catch (error) {
            console.log()
            console.error(error, "could not update time")
            isUpdatingLocalTimeOffset = false
            
        }
    }

    // initial one-time sync
    syncTimeWithServer(requestCurrentServerTime)

    // looped sync
    syncTimeWithServerIntervalId = setInterval(function () {
        syncTimeWithServer(requestCurrentServerTime)
    }, syncInterval);

    isUpdatingLocalTimeOffset = true;
}


/** Clock Class
 * @param {number} changeRate - Interval for calling changeCallback (in ms)
 * @param {(currentTime: number) => void} changeCallback - Called on time change (according to changeRate arg). Use to rerender time in DOM
 * @param {() => void} pauseCallback - do something when snooze finishes (trigger the alarm)
 * @returns {any}
 */
function Clock(changeRate,changeCallback,pauseCallback){
    this.intervalId = null;
    this.now = null; // made now into obj prop to reuse on pause callback without updading the now time
    this.changeRate = changeRate;
    /** @type {number} changeRate - Interval for calling changeCallback (in ms). Make it like 10ms to pick up the seconds change */
    this.changeRate = changeRate;
    /** @type {(currentTime: number) => void} changeCallback - Called on time change (according to changeRate arg). Use to rerender time in DOM */
    this.changeCallback = changeCallback;
    /** @type {(currentTime: number) => void} pauseCallback - do something when snooze finishes (trigger the alarm) */
    this.pauseCallback = pauseCallback;
}

/** Start Clock */
Clock.prototype.start = function(){    
    
    // initial one-time call    
    this.now = Date.now() + localTimeOffset;
    this.changeCallback(this.now)
    
    // looped call
    var self = this;
    this.intervalId = setInterval(function(){
        self.now = Date.now() + localTimeOffset;
        self.changeCallback(self.now)
    }, this.changeRate);

    if(!isUpdatingLocalTimeOffset) console.warn('clock started without local time offset updates');
};

/** Start Clock */
Clock.prototype.pause = function(){
    if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null; // Clear the interval ID
        this.pauseCallback(this.now)
    }   
}


// 'Events' NOTE: i can extend the class the old way (the commented out lines that deal with Event Emitter), but i run into problems with types that i cant resolve. It's better to rewrite it as class any way, which i can do after we check if it works with contemporary code (meaning 2012). The New Snooze event should be combined with internally emitted events
/** Snoozer Class
 * @param {EventEmitter<mySnoozeTimerEvents>} snoozeTimerSocketEmitter Emits when new snooze time is received from server
 * @param {number} changeRate Interval for calling changeCallback (in ms)
 * @returns {any}
 */
function SnoozeTimer( // TODO: ?? make var now into object property ? like in clock ?
    snoozeTimerSocketEmitter,
    changeRate = 10
    ) {    
    // EventEmitter.call(this); // NOTE: i'm doing this the stupid way, see 'events' note 
    /** @type {EventEmitter<mySnoozeTimerEvents>} Emits internal clock events*/
    this.events = snoozeTimerSocketEmitter
    /** @type {NodeJS.Timeout} */
    this.intervalId = null; // To hold the interval ID
    /** @type {EpochTimeStamp} */
    this.endTime = null; // To store the end time of the snooze    
    /** @type {boolean} */
    this.isRunning = false; // To store the end time of the snooze    
    /** @type {number} ChangeRate - Interval for calling changeCallback (in ms). Make it like 10ms to pick up the seconds change */
    this.changeRate = changeRate;
    /** @type {string} Filename of alarm audio to play */
    this.alarmFilename = '';
    /** @type {number} By how many intervals to delay the 1st play for canon effect */
    this.alarmCanonInitialDelayMultiplier = null;
    /** @type {number} Time interval between canon voices (in ms) */
    this.alarmCanonInterval = null;
    /** @type {number} Remaining delay time before initial play */
    this.alarmCanonInitialDelayRemaining = null;
    /** @type {boolean} Whether alarm should implement delay on alarm / canon start */
    this.initialDelayEnabled = true;

    this.listenToNewSnoozeTime();
    this.listenToInitialDelaySetting();
    this.listenToAlarmSoundAssignment();
    this.listenToRestartCanon();
}

// SnoozeTimer.prototype = Object.create(EventEmitter.prototype) // NOTE: see 'events' note 
// SnoozeTimer.prototype.constructor = SnoozeTimer; // NOTE: see 'events' note 

/** 
 * @type {()=>void} Finish snooze (called when snooze time runs out)
 * NOTE: **(i can also rename to terminate to better signal adequate use ?)**
 */
SnoozeTimer.prototype.finish = function(){
    if (this.intervalId) clearInterval(this.intervalId);        
    this.intervalId = null; // Clear the interval ID
    this.isRunning = false;
    this.events.emit('timerFinished')
    
} 

/** 
 * @type {()=>void} Cancel snooze (alias of finish method)
 * NOTE: keeping it for now in case i need cancel functionality at some point. **(i can also rename to terminate to better signal adequate use ?)**
 */
SnoozeTimer.prototype.cancel = function() {
    this.finish() // 
    this.events.emit('timerCanceled')
};

/** Starts the snooze countdown */
SnoozeTimer.prototype.start = function() {    

    // initial, one-time call
    var now = Date.now() + localTimeOffset
    var remainingTime = this.endTime - now;
    this.events.emit("timerStarted")
    this.events.emit("timeChanged",remainingTime) // one-time initial emit (cause interval first waits, then calls the cb)

    // looped call
    var self = this; // Preserve context for setInterval
    this.intervalId = setInterval(function() {
        var now = Date.now() + localTimeOffset
        var remainingTime = self.endTime - now;

        self.events.emit("timeChanged",remainingTime) // this can be throttled, emit only on seconds change

        // Check if the snooze time has finished
        if (remainingTime <= 0) {
            self.finish()
        }
    }, this.changeRate);

    this.isRunning = true
    if(!isUpdatingLocalTimeOffset) console.warn('snooze started without local time offset updates');
};

SnoozeTimer.prototype.listenToNewSnoozeTime = function(){ // NOTE: maybe these shouldnt be methods, but called as functions outside of the class (ie pass instance calls and passes itself as paramter on construction)
    this.events.on("newSnoozeTimeReceived",(/** @type {SetSnoozeSocketEventData} */ data)=>{
        const {snoozeTime} = data
        this.endTime = snoozeTime;
        if (Date.now() + localTimeOffset < snoozeTime){
            this.start();
        }else{
            this.finish()
        }        
    })
}

SnoozeTimer.prototype.listenToInitialDelaySetting = function(){
    this.events.on("enableInitialDelay",()=>this.initialDelayEnabled = true);
    this.events.on("disableInitialDelay",()=>this.initialDelayEnabled = false);
}

SnoozeTimer.prototype.listenToAlarmSoundAssignment = function () {
    // NOTE: nah, this is too flexible, the only case here should be that it's a first time sound assignment    
    this.events.on("whichAlarm", (/** @type {WhichAlarmData} */ data) => {
        const { alarmFileName, canonInterval, delayMultiplier } = data;
        if(!!this.alarmFilename) console.debug(`alarmFilename already set: ${this.alarmFilename} . Overwriting to ${alarmFileName} .`)  
        if(this.alarmCanonInterval != null) console.debug("alarmCanonInterval already set: . Overwriting.") 
        if(this.alarmCanonInitialDelayMultiplier != null) console.debug("alarmCanonInitialDelayMultiplier already set: . Overwriting.") 

        // Update alarmCanonInitialDelayRemaining
        if (typeof canonInterval == "number" || typeof delayMultiplier == "number") {
            if (this.alarmCanonInterval != canonInterval 
                || this.alarmCanonInitialDelayMultiplier != delayMultiplier) {
                this.alarmCanonInitialDelayRemaining = canonInterval * delayMultiplier;
                if (this.initialDelayEnabled) {
                    this.events.emit('skipAudioTo', canonInterval * delayMultiplier);
                    this.events.emit('muteForInitialDelay', [0, canonInterval * delayMultiplier]);
                }
            } else {
                console.warn("new alarm data received, but delay value is the same");
            }
        }

        // Update alarmCanonInterval
        if (typeof canonInterval == "number") {
            if (this.alarmCanonInterval != canonInterval) {
                this.alarmCanonInterval = canonInterval;
            } else {
                console.warn("new alarm data received, but interval value is the same");
            }
        } else {
            if(typeof canonInterval == "undefined"){
                console.debug("canon interval value undefinend")
            }else{
                console.error("incorrect canon interval value type: " + typeof canonInterval)
            }
        }

        // Update alarmCanonInitialDelayMultiplier
        if (typeof delayMultiplier == "number") {
            if (this.alarmCanonInitialDelayMultiplier != delayMultiplier) {
                this.alarmCanonInitialDelayMultiplier = delayMultiplier;
            } else {
                console.warn("new alarm data received, but delay multiplier is the same");
            }
        } else {
            if(typeof delayMultiplier == "undefined"){
                console.debug("delay multiplier value undefinend")
            }else{
                console.error("incorrect delay multiplier value type: " + typeof delayMultiplier)
            }
        }

        // Update alarmFilename
        if (typeof alarmFileName == "string") {
            if (this.alarmFilename != alarmFileName) {
                this.events.emit('newAlarmFilename', alarmFileName);
                this.alarmFilename = alarmFileName;
            } else {
                console.warn("new alarm data received, but filename is the same");
            }
        } else {
            if(typeof alarmFileName == "undefined"){
                console.debug("alarm filename value undefinend")
            }else{
                console.error("incorrect alarm filename value type: " + typeof delayMultiplier)
            }
        }

    })
}

// TODO: !!! write restart canon logic on server side.
SnoozeTimer.prototype.listenToRestartCanon = function (){
    this.events.on('restartCanon', ()=>{ 
        if (!this.initialDelayEnabled) console.warn("Canon restart triggered but InitialDelay flag is disabled. Overriding InitialDelay flag")
        const delay = this.alarmCanonInterval * this.alarmCanonInitialDelayMultiplier;
        this.events.emit('skipAudioTo', delay);
        this.events.emit('muteForInitialDelay', 0, delay);        
    })
}

SnoozeTimer.prototype.getLocalTimeOffset = function(){return localTimeOffset};


// ! NOTE: Snooze example usage: 
// var snoozeTimer = new SnoozeTimer(arg1,arg2,arg3);
// snoozeTimer.start(60000);

// To cancel the snooze
// snoozeTimer.cancel();

module.exports = {
    startSyncingTimeWithServer,
    Clock,
    SnoozeTimer
}