// @ts-nocheck
function debugOverlay(){
    console._log = console.log
    console.log = (args)=>{
        console._log(args)
        const text = [...args].join('')
        const innerText = document.querySelector('.debug-overlay').innerHTML;
        document.querySelector('.debug-overlay').innerHTML = text+'<br>'+innerText    
    }

    console._debug = console.debug
    console.debug = (args)=>{
        console._debug(args)
        const text = [...args].join('')
        const innerText = document.querySelector('.debug-overlay').innerHTML;
        document.querySelector('.debug-overlay').innerHTML = text+'<br>'+innerText    
    }

    console._warn = console.warn
    console.warn = (args)=>{
        console._warn(args)
        const text = [...args].join('')
        const innerText = document.querySelector('.debug-overlay').innerHTML;
        document.querySelector('.debug-overlay').innerHTML = text+'<br>'+innerText    
    }

    console._error = console.error
    console.error = (args)=>{
        console._error(args)
        const text = [...args].join('')
        const innerText = document.querySelector('.debug-overlay').innerHTML;
        document.querySelector('.debug-overlay').innerHTML = text+'<br>'+innerText    
    }
    
    document.querySelector('audio').controls = true;
    document.querySelector('audio').classList.add("debug")
    
    document.querySelector('.debug-overlay').classList.add("show")

}

var debuggingTools = {
    /** @type {(Object)=>void} */
    exposeInWindowObject(object){
        // @ts-ignore
        if(!window.debugTools) Object.assign(window,{debugTools:{}})
        // @ts-ignore
        Object.assign(window.debugTools,object)
    }
}

// !!!!!!!!!!!!!!
// debugOverlay()
// !!!!!!!!!!!!!!

module.exports = {
    debuggingTools,
}