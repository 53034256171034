const {elementsCollection} = require('./uxReference').getUxReferenceWithElementsCollection()


const alarmAudioElement = /** @type {HTMLAudioElement} */ (elementsCollection.alarmAudioElement)

function LandingPosterAwaitClick(callback) {
    const {landingPosterWrapper, landingPoster} = elementsCollection;
    landingPosterWrapper.addEventListener("click", function(e){
        e.preventDefault()
        // landingPosterWrapper.style.display = "none";
        landingPosterWrapper.classList.add("alarm-background")
        
        // enableAudio();
        console.log('enableAudio hack')
        const _muted = alarmAudioElement.muted
        alarmAudioElement.muted = true
        alarmAudioElement.play()
        alarmAudioElement.pause()
        alarmAudioElement.muted = _muted

        callback()
    },{once:true})
}

function enableAudio(){
    try {
        const audio = new Audio() 
        audio.muted = true;
        audio.play();
         setTimeout(()=>{
             audio.pause();
             audio.currentTime = 0;
         },2000)                
        
    } catch (error) {
        console.log(error)
    }
}

module.exports = LandingPosterAwaitClick;
