// TODO: handle scenario when someone connects when countdown is already running OR alarm is already playing

const {EventEmitter} = require('./EventEmitter')
const {elementsCollection} = require('./uxReference').getUxReferenceWithElementsCollection()

/**
 * Description
 * @returns {{
 *      io:any,
 *      socket:Socket,
 *      requestCurrentServerTime:(callback:(data:ServerTimeData)=>void)=>void,
 *      snoozeTimerSocketEmitter:EventEmitter<mySnoozeTimerEvents>
 * }}
 */
function setupSocket(){ // TODO: make sure to downgrade sockets to whatever is compatible with 2012 mobile browsers, same for server
    
    /** @type {any} */ //i can't find the right type in socket.io and socket.io-client it servers
    // @ts-ignore
    var io = window.io ?? globalThis.io;

    /** @type {Socket} */
    var socket = io();

    /** @type {EventEmitter<mySnoozeTimerEvents>} */
    var snoozeTimerSocketEmitter = new EventEmitter()

    /** @type {mySocketEvents} */
    var eventName = null;

    eventName = "setSnooze";
    socket.removeAllListeners(eventName);
    socket.on(eventName, function (/** @type {SetSnoozeSocketEventData}*/ data) {
        console.debug('setSnooze event received')
        snoozeTimerSocketEmitter.emit('newSnoozeTimeReceived',data);
    });

    eventName = "whichAlarm"
    socket.removeAllListeners(eventName);
    socket.on(eventName,function(data){
        /** @type {WhichAlarmData} */
        const payload = data; 
        const snoozeTimerEventName = /** @type {mySnoozeTimerEvents} */ ("whichAlarm");
        snoozeTimerSocketEmitter.emit(snoozeTimerEventName, data);
    })

    eventName = "disableInitialDelay"
    socket.on(eventName, function(){
       snoozeTimerSocketEmitter.emit("disableInitialDelay");
    })

    eventName = "enableInitialDelay"
    socket.on(eventName, function(){
       snoozeTimerSocketEmitter.emit("enableInitialDelay");
    })


    socket.on('connect', function () {                        
        eventName = "whichAlarm";
        const src = /**@type {HTMLAudioElement} */ (elementsCollection.alarmAudioElement).src // TODO: this should be abstracted into snoozer file (i don't want to interact with DOM here)
        socket.emit(eventName,src)
        // NOTE: alarm play is handled locally (without server event)
    });


    
    snoozeTimerSocketEmitter.on(
        /** @type {mySnoozeTimerEvents} */("snoozeHit"),
        function (data) {
            console.debug('snoozeHit lastSnoozeTime:', data.lastSnoozeTime)
            /** @type {HitSnoozeSocketEventData} */
            const payload = data;
            eventName = "snoozeHit"
            socket.emit(eventName, payload)
        }
    )
        

    /** Requests current server time for calcualing local time offset on clienst to sync them
     * @param {(data:ServerTimeData)=>void} callback
     */
    function requestCurrentServerTime(callback){ //TODO: Later: using this, refactor syncing in alarm clock (i dont think i use clock for now)
        eventName = 'getCurrentServerTime'
        socket.emit(eventName,callback)
        // socket.emit(eventName,function(/** @type {ServerTimeData} */ data){}
    }    

    return {
        io,
        socket,
        requestCurrentServerTime,
        snoozeTimerSocketEmitter
    }
}


module.exports = setupSocket;