/**
 * @template {string} TEventName
 * @constructor
 */
function EventEmitter() {
    /** @type {Object.<TEventName,Function[]>} */
    this.events = {};
}
/**
 * @param {TEventName} event
 * @param {Function} listener
 */
EventEmitter.prototype.on = function (event, listener) {
    if (!this.events[event]) {
        this.events[event] = [];
    }
    this.events[event].push(listener);
    console.debug("listening to event: "+event);
};
/**
 * @param {TEventName} event
 * @param {Function} listener
 */
EventEmitter.prototype.off = function (event, listener) {
    if (!this.events[event])
        return;

    this.events[event] = this.events[event].filter(function (l) {
        return l !== listener;
    });
};
/**
 * @param {TEventName} event
 */
EventEmitter.prototype.emit = function (event /*, args... */) {
    if (!this.events[event])
        return;

    var args = Array.prototype.slice.call(arguments, 1);
    this.events[event].forEach(function (listener) {
        listener.apply(null, args);
    });


    if(["timeChanged"].findIndex(e=>(e==event)) != -1){

    }else{
        console.log("event emitted: "+ event);
        console.groupCollapsed();
        console.trace();
        console.groupEnd();
    }
};

module.exports = {
    EventEmitter
}