const { SnoozeTimer } = require("./alarmClock");
const { elementsCollection } = require("./uxReference").getUxReferenceWithElementsCollection();


/**
 * sets up user interaction listeners
 * @param {SnoozeTimer} snoozeTimer
 * @returns {any}
 */
function listenToUiEvents(snoozeTimer){        
    
    // send hit event when snooze button clicked
    elementsCollection.snoozeButton.addEventListener("click",(e)=>{
        e.preventDefault();
        if(elementsCollection.snoozeButton.getAttribute('data-isActive') == "false") return;
        const lastSnoozeTime = snoozeTimer.endTime
        /** @type {HitSnoozeEventData} */
        const payload = {lastSnoozeTime}
        snoozeTimer.events.emit('snoozeHit',payload)
    })

}

module.exports = {
    listenToUiEvents
}