
/** @type {AlarmClockUxReference<string>} */
var classesDictionary = {
    snoozeWrapper:"snooze-wrapper",
    snoozeButtonWrapper:"snooze-button-wrapper",
    snoozeButton:"snooze-button",
    snoozeSliderWrapper:"snooze-slider-wrapper",
    snoozeCountdownWrapper:"snooze-countdown-wrapper",
    snoozeCountdownDisplay:"snooze-countdown-display",
    alarmAudioElement:"alarm-audio",
    landingPosterWrapper:"landing-poster-wrapper",
    landingPoster:"landing-poster"
}


/**
 * Description
 * @returns {{classesDictionary:AlarmClockUxReference<string>}}
 */
function getUxReference(){
    var uxReference = {};
    uxReference.classesDictionary = classesDictionary
    return uxReference;
}


/**
 * Description
 * @returns {{
 * classesDictionary:AlarmClockUxReference<string>,
 * elementsCollection:AlarmClockUxReference<HTMLElement|undefined>}}
 */
function getUxReferenceWithElementsCollection(){
    var uxReference = {};
    uxReference.classesDictionary = classesDictionary
    var elementsCollection = {};
    Object.entries(uxReference.classesDictionary).forEach(function(pair){
        var element = document.querySelector(`.${pair[1]}`);
        if(element instanceof HTMLElement){
            elementsCollection[pair[0]] = element;
        }else{
            console.warn('Failed to select element with class: '+pair[1])
        }         
    })
    uxReference.elementsCollection = /** @type {AlarmClockUxReference<HTMLElement|undefined>}*/ (elementsCollection);
    return uxReference
}


module.exports = {
    getUxReference,
    getUxReferenceWithElementsCollection
}